import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25cb4657 = () => interopDefault(import('../../../pages/old/App.vue' /* webpackChunkName: "" */))
const _72d62a36 = () => interopDefault(import('../../../pages/old/home/home.vue' /* webpackChunkName: "" */))
const _8a9a8536 = () => interopDefault(import('../../../pages/old/ajkRank/xiaoqu/communityHot.vue' /* webpackChunkName: "" */))
const _67d671f4 = () => interopDefault(import('../../../pages/old/rank/borkerRank/borkerRank.vue' /* webpackChunkName: "" */))
const _b28e3afc = () => interopDefault(import('../../../pages/old/rank/storeRank/storeRank.vue' /* webpackChunkName: "" */))
const _2b4d0416 = () => interopDefault(import('../../../pages/old/ajkRank/qiangshou/index.vue' /* webpackChunkName: "" */))
const _811e93fe = () => interopDefault(import('../../../pages/old/wakeAPP/wakeAPP.vue' /* webpackChunkName: "" */))
const _cc56d38a = () => interopDefault(import('../../../pages/captcha-wangyi/index.vue' /* webpackChunkName: "" */))
const _9083b172 = () => interopDefault(import('../../../pages/captcha-geetest/index.vue' /* webpackChunkName: "" */))
const _4a553a3d = () => interopDefault(import('../../../pages/trendency/index.vue' /* webpackChunkName: "" */))
const _d6edbb1c = () => interopDefault(import('../../../pages/old/ajkRank/synList/synList.vue' /* webpackChunkName: "" */))
const _36738572 = () => interopDefault(import('../../../pages/esf-package/index.vue' /* webpackChunkName: "" */))
const _31c383c6 = () => interopDefault(import('../../../pages/map/index.vue' /* webpackChunkName: "" */))
const _64f63174 = () => interopDefault(import('../../../pages/agreement/carrier/index.vue' /* webpackChunkName: "" */))
const _3179b26a = () => interopDefault(import('../../../pages/weak/miniProgram/index.vue' /* webpackChunkName: "" */))
const _6ea8624a = () => interopDefault(import('../../../pages/common-material/index.vue' /* webpackChunkName: "" */))
const _1bd79770 = () => interopDefault(import('../../../pages/samsung-download/index.vue' /* webpackChunkName: "" */))
const _3bd1a66b = () => interopDefault(import('../../../pages/app/download/index.vue' /* webpackChunkName: "" */))
const _6222cd93 = () => interopDefault(import('~/layouts/error.vue' /* webpackChunkName: "" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _25cb4657,
    alias: "/feapp",
    name: "App",
    children: [{
      path: ":city([a-z]+)/",
      component: _72d62a36,
      name: "mHome"
    }, {
      path: "ershou-autorank/xiaoqu",
      component: _8a9a8536,
      name: "communityHot"
    }, {
      path: "ershou-autorank/broker",
      component: _67d671f4,
      name: "brokerRank"
    }, {
      path: "ershou-autorank/store",
      component: _b28e3afc,
      name: "storeRank"
    }, {
      path: "ershou-autorank/fangyuan/qiangshou/*",
      component: _2b4d0416,
      name: "qiangshou"
    }, {
      path: "ershou-autorank/fangyuan/pinzhi/*",
      component: _2b4d0416,
      name: "pinzhi"
    }, {
      path: "ershou-autorank/fangyuan/*",
      component: _2b4d0416,
      name: "houseRank"
    }, {
      path: "/wake/app",
      component: _811e93fe,
      name: "wakeApp"
    }]
  }, {
    path: "/esfcommon-captcha-wy",
    component: _cc56d38a,
    name: "captcha"
  }, {
    path: "/esfcommon-captcha-geetest",
    component: _9083b172,
    name: "captcha"
  }, {
    path: "/pcesfcommon-captcha-wy",
    component: _cc56d38a,
    name: "captcha"
  }, {
    path: "/pcesfcommon-captcha-geetest",
    component: _9083b172,
    name: "captcha"
  }, {
    path: "/:city([a-z]+)/trendency/",
    component: _4a553a3d,
    name: "city-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)_:block_id(\\d+)/",
    component: _4a553a3d,
    name: "block-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)/",
    component: _4a553a3d,
    name: "area-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/community/:comm_id",
    component: _4a553a3d,
    name: "community-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)-q-:shangquan_id(\\d+)/",
    component: _4a553a3d,
    name: "shangquan-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_py(\\w+)/",
    component: _4a553a3d,
    name: "area-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_py(\\w+)-q-:shangquan_py(\\w+)/",
    component: _4a553a3d,
    name: "shangquan-trendency"
  }, {
    path: "/ershou-autorank/synList",
    component: _d6edbb1c,
    name: "syn-rankList"
  }, {
    path: "/feapp/ershou-autorank/synList",
    component: _d6edbb1c,
    name: "syn-rankList-wb"
  }, {
    path: "/ershou-autorank/common",
    component: _36738572,
    name: "syn-com"
  }, {
    path: "/:city([a-z]+)/map/community",
    component: _31c383c6,
    name: "map"
  }, {
    path: "/xiaoqu/([a-zA-Z0-9]+)/map/community",
    component: _31c383c6,
    name: "mapwb"
  }, {
    path: "/agreement/carrier/:id",
    component: _64f63174,
    name: "agreement"
  }, {
    path: "/weak/miniProgram/esf",
    component: _3179b26a,
    name: "weakMiniProgramESF"
  }, {
    path: "/clbCfg/common/material",
    component: _6ea8624a,
    name: "commonMaterial"
  }, {
    path: "/clb_cfg/menu/materialtemplate/materialtemplate",
    component: _6ea8624a,
    name: "commonMaterialPath"
  }, {
    path: "/agreement/samsungDownload",
    component: _1bd79770,
    name: "samsungDownload"
  }, {
    path: "/esfcommon/app/download",
    component: _3bd1a66b,
    name: "appDownload"
  }, {
    path: "*",
    component: _6222cd93,
    name: "Not Found"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
